<template>
  <div class="JewelryMall">
     <CompMailHeader />
     <div class="tab-box">
        <div class="tab-item" :class="tabindex === 0 ? 'tab-item-active' : ''" @click="tabChange(0)">
          提货
        </div>
        <div class="tab-item" :class="tabindex === 1 ? 'tab-item-active' : ''" @click="tabChange(1)">
          回收
        </div>
      </div>
    <div class="JewelryMall-box2">
      <hotGoods v-if="current === 0" :tabindex="tabindex" />
    </div>
    <Tabbar />
</div>

</template>

<script>
import leftFloat from "@/components/left-float/index.vue";
import hotGoods from "./components/hotGoods.vue";
import record from "../classicsBox/components/record.vue";
import bestFalling from "../classicsBox/components/bestFalling.vue";
import Hprice from '../../components/common/Hprice.vue';
import {mapMutations } from "vuex";
export default {
  name: "Lucky",
  components: {
    leftFloat,
    hotGoods,
    record,
    bestFalling,
    Hprice
  },
  data() {
    return {
      recordList: [],
      current: 0,
      allStatus: false,
      dropActive: 0,
      restStatus: false,
      loadingShow: true,
      tabindex: 0,
      isChoosedNum:2,
    };
  },
  created() {
    console.log(this.current,'current')
  },
  methods: {
    ...mapMutations([
      "User",
      "GetIndividualsShow",
      "Leave",
      "GetRegisterShow",
      "GetSignInShow",
      "SetPopupHighLightShow",
    ]),
    // tab切换
    tabChange(i) {
      this.tabindex = i;
      if (i === 1) {
        // this.getLuckypageList();
      }
    },

    dropItemClick(i) {
      this.dropActive = i;
    },
    restChange() {
      this.restStatus = true;
      setTimeout(() => {
        this.restStatus = false;
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.JewelryMall {
  position: relative;
  .tab-box {
    width: 2.1rem;
    height: 0.38rem;
    background: #161514;
    margin: 0 auto;
    margin-top: 0.54rem;
    display: flex;
    align-items: center;
    padding: 0 0.06rem;
     font-size: 0.14rem;
     
    .tab-item {
         width: 0.96rem;
       height: 0.3rem;
      background: url("../../assets/newImages/record-table.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 0.12rem;
      color: #b79f79;
       margin-right: 0.1rem;
      
    }
    .tab-item-active {
     background: url("../../assets/newImages/record-actable.png") no-repeat;
    background-size: 100% 100%;
    color: #ffffff;
    }
  }
}
.JewelryMall-box2 {
  width: 3.75rem;
  margin: 0 auto;
  overflow: hidden;
  padding: 0.15rem 0.18rem 0.15rem;
  margin-bottom: 0.42rem;

  .enter {
    color: #ecb105;
  }
}

.search-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  .s-l,
  .s-r,
  .average,
  .num,
  .num-item {
    display: flex;

    .text1 {
      font-size: 0.26rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }

    .text2 {
      font-size: 0.18rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.35rem;
      display: inline-block;
      margin-left: 0.2rem;
    }
  }
}

.num,
.num-item {
  justify-content: center;
  align-items: center;
}

.average {
  width: 3.73rem;
  height: 0.41rem;
  background: #000000;
  border-radius: 0.2rem 0rem 0rem 0rem;
  box-shadow: inset 0px 0px 7px 3px #267b81;
  font-size: 0.16rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  align-items: center;

  .text {
    line-height: 0.41rem;
    margin: 0 0.3rem 0 0.3rem;
  }

  span {
    display: block;
    line-height: 0.41rem;
  }

  img {
    width: 0.18rem;
    height: 0.25rem;
    margin-right: 0.07rem;
  }

  .num-item {
    margin-right: 0.17rem;

    &:first-child {
      color: #ff9933;
    }

    &:last-child {
      color: #6699ff;
    }
  }
}

.btn {
  margin-left: 0 !important;
}

.prob-sort {
  width: 2.27rem;
  height: 0.41rem;
  background: #000000;
  border-radius: 0.2rem 0rem 0rem 0rem;
  box-shadow: inset 0px 0px 7px 3px #267b81;
  font-size: 0.16rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  text-align: center;
  line-height: 0.41rem;
  margin-left: 0.12rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 0.2rem;

  span {
    cursor: pointer;
    white-space: nowrap;
  }

  i {
    font-style: normal;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.07rem 0.09rem 0 0.09rem;
    border-color: #999999 transparent transparent transparent;
  }
}

.btn2 {
  width: 1.02rem;
  height: 0.41rem;
  background: #0f3032;
  font-size: 0.18rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 0.41rem;
  box-shadow: inset 0px 0px 5px 3px #267b81;
  cursor: pointer;
  white-space: nowrap;
}

.btn-all {
  box-shadow: none;
  background: none;
}

.rest {
  width: 0.2rem !important;
  height: 0.2rem !important;
  margin-left: 0 !important;
}

.title-item {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 0.18rem;
    height: 0.25rem;
    margin-right: 0.04rem;
  }
}

.user-img {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-start;
  // margin-right: 10px;

  img {
    width: 0.2rem;
    height: 0.2rem;
    border-radius: 50%;
    margin-right: 0.05rem;
  }
}

.box-list {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 0.05rem;

  img {
    width: 0.3rem;
  }
}



   
</style>
