<template>
  <div class="comp-hot-box">
    <div class="input-box">
      <div class="search-box">
        <input
          type="text"
          placeholder="请输入想要搜索的商品"
          v-model="searchvalue" />
        <div class="searchenter" @click="searchvuleclick()">
          <img src="@/assets/newImages/search-icon.png" alt="" />
        </div>
      </div>
      <div class="price-box">
        <span class="price">价格</span>
        <div class="triangle-icon">
          <img
            src="@/assets/newImages/price-change.png"
            alt="Up Triangle"
            @click="GetSort" />
        </div>
      </div>
    </div>
    <div class="choose-all-box">
      <div class="choose-all">
        <div class="rem-checkbox" @click="toggleAllCheck">
          <div v-show="IS_Select_All" class="float-gou-icon">
            <div class="bg-img"></div>
          </div>
        </div>
        全选
      </div>
      <div class="goods-totle">共{{ total }}件</div>
    </div>
    <div class="JewelryMall-list">
      <div
        v-for="(item, index) in WarehouseLists"
        :key="index"
        :class="{
          'JewelryMall-list-item-actived': chooseIdList.includes(item.id),
          'JewelryMall-list-item': !chooseIdList.includes(item.id),
        }"
        @click="itemOnClick(item, index)">
        <div>
          <Hprice v-if="tabindex === 0" :price="item.bean" />
          <Cint v-else :price="item.bean" />
        </div>
        <!-- # 背包显示钱袋、钱堆
            item.is_purse==1 &&  item.type =3
            item.is_purse==1 &&  item.type =0 -->
        <div
          class="money-bag-box"
          v-if="item.type === 3 && item.is_purse === 1">
          <img src="../../../assets/newImages/money-bag.png" alt="" />
        </div>
        <div
          class="moeny-pile-box"
          v-else-if="
            (item.type === 0 || item.type === 10) && item.is_purse === 1
          ">
          <img src="../../../assets/newImages/moeny-pile.png" alt="" />
        </div>
        <template v-else>
          <div class="pic">
            <img v-lazy="item.cover" alt="" />
          </div>
          <div class="item-data">
            <p class="name elips1">{{ item.name }}</p>
            <div class="title2">
              {{ item.dura_alias }}
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="luckypagination">
      <Pagination
        :total="PageTotal"
        :page.sync="page_num"
        :limit.sync="per_page"
        @pagination="handleCurrentChange" />
    </div>
    <div class="table-footer">
      <div class="footer-text">已选{{ chooseNum }}件</div>
      <Hprice v-if="tabindex === 0" :price="allPrice.toFixed(2)" />
      <Cint v-else :price="allPrice.toFixed(2)" />
      <div class="btn-submit" v-if="tabindex === 0" @click="WholeExtract">
        提货
      </div>
      <div class="btn-submit" v-else @click="WholeCash">回收</div>
    </div>
  </div>
</template>

<script>
import { Storage, Extract, Cash, Give, PersonalInfo } from "@/network/api.js";
import { mapMutations } from "vuex";
import { priceSearch } from "../../Lucky/index.js";
import Hprice from "../../../components/common/Hprice.vue";
export default {
  name: "JewelryMall",
  components: {
    Hprice,
  },
  props: {
    tabindex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      sort: 0, //0按照价格升序排序  2 按照价格降序排序
      searchvalue: "",
      loadingShow: true,

      WarehouseLists: [],
      //分页
      per_page: 0,
      PageTotal: 0,
      page_num: 1, //页码

      priceSearch,
      current: 0,
      priceSearchIndex: undefined,
      total: 0,
      chooseIdList: [],
      bean: 0,
      chooseNum: 0, //选中数量
      AllWarehouseObj: {},
    };
  },
  created() {
    this.GetStorage(this.page_num, 0);
  },
  computed: {
    SelectList() {
      const array = this.priceselectList.map((item) => {
        return {
          label: item?.label || item,
          pvalue: item?.pvalue || item,
        };
      });
      return array;
    },
    parseSelectList() {
      const array = this.selectList.map((item) => {
        return {
          label: item?.label || item,
          value: item?.value || item,
        };
      });
      return array;
    },
    allPrice() {
      let price = 0;
      this.chooseIdList.forEach((item) => {
        price = price * 1 + this.AllWarehouseObj[item + ""].bean * 1;
      });
      return price;
    },
    WarehouseIdLists() {
      const arr = this.WarehouseLists.map((item) => {
        return item.id;
      });
      return arr;
    },
    IS_Select_All() {
      let flag = true;
      this.WarehouseIdLists.forEach((item) => {
        if (!this.chooseIdList.includes(item)) {
          flag = false;
        }
      });
      return flag;
    },
  },
  watch: {
    WarehouseLists(val) {
      setTimeout(() => {
        this.loadingShow = false;
      }, 100);
      val.forEach((item) => {
        this.AllWarehouseObj[item.id + ""] = item;
        if (!this.chooseIdList.includes(item.id)) {
        }
      });
    },
    tabindex(newVal, oldVal) {
      if (newVal === 0) {
        //提货
        this.GetStorage(this.page_num, 0);
      } else if (newVal === 1) {
        //回收
        this.GetStorage(this.page_num, 0);
      }
    },
  },
  methods: {
    //获取仓库列表
    GetStorage(num, status) {
      this.loadingShow = true;
      Storage(num, this.sort, status, this.searchvalue).then((res) => {
        if (res.data.data) {
          this.loadingShow = false;
          this.WarehouseLists = res.data.data.data;
          this.total = Number(res.data.data.total);
          this.PageTotal = Number(res.data.data.total);
          this.per_page = Number(res.data.data.per_page);
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
    },
    //选中
    itemOnClick(item, index) {
      if (this.chooseIdList.includes(item.id)) {
        this.chooseIdList.splice(this.chooseIdList.indexOf(item.id), 1);
        this.bean -= item.bean * 1;
        this.chooseNum -= 1;
      } else {
        this.bean += item.bean * 1;
        this.chooseNum += 1;
        this.chooseIdList.push(item.id);
      }
    },
    //多选提取
    WholeExtract() {
      let data = this.chooseIdList;
      Extract(data).then((res) => {
        if (res.data.code == 200) {
          this.chooseNum = 0;
          this.allPrice = 0;
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.GetPersonalInfo();
          this.GetStorage(this.page_num);
        } else {
          this.chooseIdList = data;
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
      this.chooseIdList = [];
    },
    //多选回收
    WholeCash() {
      let data = this.chooseIdList;
      Cash(data).then((res) => {
        this.chooseNum = 0;
        this.allPrice = 0;
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.GetPersonalInfo();
          this.GetStorage(this.page_num);
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
      this.chooseIdList = [];
    },
    //价格排序
    GetSort() {
      if (this.sort === 0) {
        this.sort = 1;
      } else if (this.sort === 1) {
        this.sort = 0;
      }
      console.log(this.page_num);
      this.GetStorage(this.page_num);
    },
    //全选
    toggleAllCheck() {
      if (this.IS_Select_All == false) {
        const array = [
          ...new Set([...this.chooseIdList, ...this.WarehouseIdLists]),
        ];
        this.chooseIdList = array;
        this.chooseNum = this.chooseIdList.length;
      } else {
        this.chooseIdList = this.chooseIdList.filter((item) => {
          return !this.WarehouseIdLists.includes(item);
        });
        this.chooseNum = this.chooseIdList.length;
      }
    },
    handleCurrentChange(val) {
      this.GetStorage(val.page);
      this.page_num = val.page;
    },
    async getKeyValuedata() {
      Storage(this.page_num, this.sort, 0, this.searchvalue).then((res) => {
        if (res.data.data.data.length > 0) {
          this.WarehouseLists = res.data.data.data;
          this.total = Number(res.data.data.total);
          this.PageTotal = Number(res.data.data.total);
          this.per_page = Number(res.data.data.per_page);
        }
      });
    },
    // 搜索商品
    searchvuleclick() {
      if (this.searchvalue == null) {
        this.$message.error("请输入你想要搜索的物品。");
      } else if (this.searchvalue == "") {
        this.searchvalue = null;
        this.getKeyValuedata();
      } else {
        this.getKeyValuedata();
      }
    },
    //个人信息
    GetPersonalInfo() {
      PersonalInfo().then((res) => {
        this.User(res.data.data);
      });
    },

    ...mapMutations(["User"]),
  },
};
</script>

<style lang="scss" scoped>
.comp-hot-box {
  position: relative;
}
.input-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  // margin-bottom: 0.16rem;
  .search-box {
    display: flex;
    align-items: center;
    width: 100%;
    height: 0.26rem;
    input {
      width: 2rem;
      height: 100%;
      border: 0.01rem solid #817043;
      text-decoration: none;
      background: rgba(42, 27, 0, 0.6);
      text-indent: 0.08rem;
      color: #a4a4a4;
      font-size: 0.12rem;
    }
    .searchenter {
      width: 0.5rem;
      height: 0.26rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url("../../../assets/newImages/search-btn-bg.png") no-repeat;
      background-size: 100% 100%;
      img {
        width: 0.16rem;
        height: 0.16rem;
      }
    }
  }

  .price-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 1.15rem;
    height: 0.26rem;
    margin-left: 0.16rem;
    font-size: 0.12rem;
    text-indent: 2ch;
    color: #a4a4a4;
    line-height: 0.22rem;
    text-align: left;
    font-style: normal;
    background: url(../../../assets/newImages/price-change-bg.png) no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;
    position: relative;

    .triangle-icon {
      position: absolute;
      right: 0.01rem;

      img {
        width: 0.19rem;
        height: 0.19rem;
      }
    }
  }
}

.choose-all-box {
  width: 100%;
  height: 0.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 0.1rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 0.12rem;
  color: #ffffff;

  .rem-checkbox {
    position: relative;
    width: 0.12rem;
    height: 0.12rem;
    background: #413f3e;
    margin-right: 0.08rem;
    .float-gou-icon {
      position: absolute;
      width: 0.16rem;
      height: 0.16rem;
      left: 0;
      bottom: 0;
      @include bgImg("check-box-active");
    }
  }

  .choose-all {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .checkbox-input {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    background-color: #413f3e;
  }
  /* 隐藏默认的复选框样式 */
  input[type="checkbox"]:checked {
    -webkit-appearance: none;
  }
  .checkbox-input:checked {
    background-color: transparent;
    background: url(../../../assets/newImages/check-box-active.png) no-repeat;
    background-size: 100% 102%;
    background-position: 0 0;
    border-color: transparent;
  }
}
.JewelryMall-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .JewelryMall-list-item {
    width: 1.06rem;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 0.03rem;
    padding: 0.06rem 0.13rem;
    margin-bottom: 0.07rem;
    background-color: #1d1300;
    border: 0.01rem solid #69552d;

    .title2 {
      font-size: 0.1rem;
    }

    .money-bag-box {
      width: 0.93rem;
      height: 0.93rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .moeny-pile-box {
      width: 0.93rem;
      height: 0.93rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .pic {
      width: 100%;
      height: 0.6rem;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: cover;
      position: relative;
      z-index: 1;

      img {
        display: block;
        height: 100%;
      }
    }

    .item-data {
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      font-size: 0.1rem;

      .title2 {
        font-size: 0.1rem;
      }
    }
  }
  .JewelryMall-list-item-actived {
    width: 1.06rem;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 0.03rem;
    padding: 0.06rem 0.13rem;
    margin-bottom: 0.07rem;
    background-color: #1d1300;
    background-image: url(../../../assets/newImages/box-active-bg.png);
    background-size: 100% 100%;

    .title2 {
      font-size: 0.1rem;
    }

    .money-bag-box {
      width: 0.93rem;
      height: 0.93rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .moeny-pile-box {
      width: 0.93rem;
      height: 0.93rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .pic {
      width: 100%;
      height: 0.6rem;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: cover;
      position: relative;
      z-index: 1;

      img {
        display: block;
        height: 100%;
      }
    }

    .item-data {
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      font-size: 0.1rem;

      .title2 {
        font-size: 0.1rem;
      }
    }
  }
}
.luckypagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.1rem 0;
}
.table-footer {
  position: fixed;
  bottom: 0.7rem;
  left: 0;
  width: 100%;
  height: 0.5rem;
  background: #4f4424;
  z-index: 99;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.27rem;
  .footer-text {
    margin-right: 0.2rem;
    font-size: 0.12rem;
    margin-left: -0.06rem;
  }

  .btn-submit {
    position: absolute;
    right: 0.62rem;
    width: 0.95rem;
    height: 0.3rem;
    @include bgImg("search-btn-bg");
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 0.14rem;
    color: #000000;
  }
}

.PageTotal {
  width: 100%;

  li {
    background: #111111 !important;

    &.active {
      background: #4854c9 !important;
    }

    &:hover {
      color: #fff !important;
    }
  }

  .btn-prev,
  .btn-next {
    background: #111111;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .wrapper-box {
    z-index: 2001;
    width: 9rem;
    position: relative;
    background-image: url("../../../assets/images/other/jinkuang.png");
    background-size: 100% 100%;
    background-color: #131426;
    overflow: hidden;
    padding: 0 0.35rem;
    box-sizing: border-box;

    .wrapper-box-top {
      width: 100%;
      height: 0.03rem;
    }

    .tit {
      width: 100%;
      font-size: 0.24rem;
      text-align: center;
      line-height: 0.8rem;
    }

    .other {
      width: 100%;
      height: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 0.2rem;

      .ShuLiang {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          width: 0.3rem;
          height: 0.3rem;
          background: rgba(255, 255, 255, 0.08);
          text-align: center;
          line-height: 0.3rem;
          font-size: 0.16rem;
          cursor: pointer;

          &:nth-child(2) {
            width: 0.5rem;
            margin: 0 0.1rem;
          }
        }
      }

      .ZongJiaGe {
        display: flex;
        align-items: center;

        p {
          font-size: 0.18rem;
        }

        span {
          margin-top: 0.03rem;
          width: 0.7rem;
          display: flex;

          img {
            vertical-align: middle;
            width: 0.2rem;
            height: 0.2rem;
            margin-bottom: 0.02rem;
          }

          font-size: 0.18rem;
          color: #75cd66;
        }
      }
    }

    .pic {
      width: 2.75rem;
      height: 2.75rem;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      /*background: url(../../assets/images/PublicImg/item-bg.jpg) no-repeat center;*/
      border: 1px solid;
      border-image: linear-gradient(rgba(0, 0, 0, 0), #ecb105) 20 20;

      background-size: cover;

      img {
        max-width: 80%;
        max-height: 80%;
      }
    }

    .btns {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 0.2rem;

      span {
        display: block;
        width: 3rem;
        height: 0.64rem;
        background: hsla(0, 0%, 100%, 0.06);
        /*border: 1px solid hsla(0, 0%, 100%, .2);*/
        color: #e4e4e4;

        text-align: center;
        line-height: 0.64rem;
        font-size: 0.14rem;
        margin-right: 0.2rem;
        cursor: pointer;
      }

      .btn-two {
        margin-right: 0;
        /*background: #ffd322;*/
        background-image: url("../../../assets/images/OpenBox/btn.jpg");
        background-size: 100% 100%;
        color: #fff;
      }
    }

    .info {
      margin-top: 0.3rem;
      text-align: center;
      font-size: 0.12rem;
      color: rgba(255, 255, 255, 0.53);
    }

    .cancel {
      width: 0.5rem;
      height: 0.5rem;
      position: absolute;
      top: 0;
      right: 0;
      text-align: center;
      line-height: 0.5rem;

      img {
        width: 0.15rem;
        cursor: pointer;
      }
    }
  }
}
</style>
